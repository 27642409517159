"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EcommerceTrackingData = void 0;
class EcommerceTrackingData {
    constructor(productId, listPageType, listName, organisationId, disciplines, premium, listPosition, unitPrice, currency) {
        this.productId = productId;
        this.listPageType = listPageType;
        this.listName = listName;
        this.organisationId = organisationId;
        this.disciplines = disciplines;
        this.premium = premium;
        this.listPosition = listPosition;
        this.unitPrice = unitPrice;
        this.currency = currency;
    }
    getProductId() {
        return this.productId;
    }
    getOrganisationId() {
        return this.organisationId;
    }
    getDisciplines() {
        if (!this.disciplines) {
            return undefined;
        }
        return this.disciplines.map(discipline => discipline.toString()).join("/");
    }
    getUnitPrice() {
        if (!this.unitPrice) {
            return undefined;
        }
        return this.unitPrice.toString();
    }
    getCurrency() {
        if (!this.currency || this.currency.length !== 3) {
            return undefined;
        }
        return this.currency.toString();
    }
}
exports.EcommerceTrackingData = EcommerceTrackingData;
