"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartnerIncentiveTracker = void 0;
const error_message_1 = require("../../enums/error-message");
class PartnerIncentiveTracker {
    constructor(tracker) {
        this.tracker = tracker;
    }
    trackClick(trackingData, eventParams) {
        if (this.tracker === null) {
            throw new Error(error_message_1.ErrorMessage.DATA_LAYER_TRACKER_UNAVAILABLE);
        }
        this.tracker.trackECommerceClickEvent({
            eventParams,
            listEntityType: trackingData.listEntityType,
            listName: trackingData.listName,
            listPageType: trackingData.listPageType,
            item: trackingData.getTrackingContext()
        });
    }
    trackImpression(trackingData, eventParams) {
        var _a, _b;
        this.tracker.trackECommerceImpressionEvent({
            eventParams,
            listEntityType: trackingData.listEntityType,
            listName: (_a = trackingData.listName) !== null && _a !== void 0 ? _a : '',
            listPageType: (_b = trackingData.listPageType) !== null && _b !== void 0 ? _b : '',
            item: trackingData.getTrackingContext()
        });
    }
}
exports.PartnerIncentiveTracker = PartnerIncentiveTracker;
