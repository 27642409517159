import { InteractionEvent } from '@/infrastructure/outbound';
import { IDataLayer, ITrackingData } from '@/types';
import { IDataLakeEventDispatcher } from './data-lake-event-dispatcher.interface';
import { EventType } from '@/enum';
import { IEcommerceEvent } from '../events/ecommerce-event.interface';

export class DataLakeEventDispatcher implements IDataLakeEventDispatcher {
	private dataLayer: IDataLayer;

	constructor() {
		this.dataLayer = window['dataLayer'] as IDataLayer;
	}

	public trackStructuredEvent(
		params: ITrackingData,
		eventName: string
	): void {
		this.dataLayer.push({ eventParams: null });

		this.dataLayer.push(
			new InteractionEvent({
				eventName,
				...params,
			}),
		);
	}

	public trackECommerceClickEvent(event: IEcommerceEvent): void {
		this.dataLayer.push({ item: null, eventParams: null });

		this.dataLayer.push({
			event: EventType.SELECT_ITEM,
			...event
		});
	}

	public trackECommerceImpressionEvent(event: IEcommerceEvent): void {
		this.dataLayer.push({ item: null, eventParams: null });

		this.dataLayer.push({
			event: EventType.VIEW_ITEM_LIST,
			...event
		});
	}

	public trackProductView(event: IEcommerceEvent): void {
		this.dataLayer.push({ item: null, eventParams: null });

		this.dataLayer.push({
			event: EventType.VIEW_ITEM,
			...event
		});
	}
}
