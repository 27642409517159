"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartnerIncentiveClickEcommerceTrackingData = void 0;
const product_type_1 = require("../enums/product-type");
const click_ecommerce_tracking_data_class_1 = require("./click-ecommerce-tracking-data.class");
class PartnerIncentiveClickEcommerceTrackingData extends click_ecommerce_tracking_data_class_1.ClickEcommerceTrackingData {
    constructor(productId, listPageType, listEntityType, listName, organisationId, listPosition) {
        super(productId, listPageType, listName, organisationId, undefined, undefined, listPosition, undefined, undefined, product_type_1.ProductType.PARTNER);
        this.productId = productId;
        this.listPageType = listPageType;
        this.listEntityType = listEntityType;
        this.listName = listName;
        this.organisationId = organisationId;
        this.listPosition = listPosition;
    }
    getTrackingContext() {
        var _a, _b;
        return {
            organisationId: this.getOrganisationId(),
            studyId: undefined,
            scholarshipId: undefined,
            bodyId: undefined,
            premiumnessLevel: (_b = (_a = this.premium) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '',
            price: undefined,
            position: this.listPosition,
            currency: undefined
        };
    }
}
exports.PartnerIncentiveClickEcommerceTrackingData = PartnerIncentiveClickEcommerceTrackingData;
