"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniversityCardTracker = void 0;
const list_entity_type_1 = require("../../enums/list-entity-type");
const card_tracker_1 = require("./card-tracker");
class UniversityCardTracker extends card_tracker_1.CardTracker {
    constructor(tracker) {
        super(tracker);
    }
    getListEntityType() {
        return list_entity_type_1.ListEntityType.ORGANISATION;
    }
}
exports.UniversityCardTracker = UniversityCardTracker;
