export enum EventType {
	PAGE_VIEW = 'Pageview',
	INTERACTION = 'interaction',
	PURCHASE = 'Purchase',
	UPDATE = 'update',
	DATA_LAYER_INITIALISED = 'DataLayerInitialised',
	SELECT_ITEM = 'select_item',
	VIEW_ITEM_LIST = 'view_item_list',
	VIEW_ITEM = 'view_item'
}
