"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionValidator = void 0;
const action_1 = require("../../enums/action");
const error_message_1 = require("../../enums/error-message");
const validation_error_1 = require("../validation-error");
const snake_case_validator_1 = require("./snake-case-validator");
class ActionValidator {
    constructor() {
        this.snakeCaseValidator = new snake_case_validator_1.SnakeCaseValidator();
    }
    validate(trackingData) {
        const action = trackingData.action;
        if (typeof action === 'undefined')
            return;
        if (!Object.values(action_1.Action).includes(action)) {
            throw new validation_error_1.ValidationError(error_message_1.ErrorMessage.INVALID_ACTION);
        }
        this.snakeCaseValidator.validate(action);
    }
}
exports.ActionValidator = ActionValidator;
