"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventValidator = void 0;
class EventValidator {
    constructor() {
        this.validators = [];
    }
    addValidator(validator) {
        this.validators.push(validator);
    }
    validate(trackingObject) {
        for (const validator of this.validators) {
            validator.validate(trackingObject);
        }
    }
}
exports.EventValidator = EventValidator;
