"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tracker = void 0;
const tracker_importer_1 = require("./tracker-importer");
const category_validator_1 = require("../validators/category-validator");
const event_validator_1 = require("../validators/event-validator");
const action_validator_1 = require("../validators/action-validator");
const label_validator_1 = require("../validators/label-validator");
const property_validator_1 = require("../validators/property-validator");
const value_validator_1 = require("../validators/value-validator");
const product_1 = require("../../enums/product");
const error_message_1 = require("../../enums/error-message");
const event_name_1 = require("../../enums/event-name");
const scholarship_card_tracker_1 = require("./scholarship-card-tracker");
const study_card_tracker_1 = require("./study-card-tracker");
const partner_incentive_tracker_1 = require("./partner-incentive-tracker");
const university_card_tracker_1 = require("./university-card-tracker");
const product_view_tracker_1 = require("./product-view-tracker");
class Tracker {
    constructor(product, trackWithGoogleAnalyics = false, trackWithSnowplow = true) {
        this.product = product;
        this.dataLayerTracker = null;
        this.trackerImporter = null;
        this.structuredEventValidator = this.initStructuredEventValidators();
        this.trackWithGoogleAnalytics = trackWithGoogleAnalyics;
        this.trackWithSnowplow = trackWithSnowplow;
        this.initTrackerSupport();
    }
    trackStructuredEvent(trackingData, eventName = event_name_1.EventName.LEGACY_EVENT) {
        if (!this.dataLayerTracker) {
            throw new Error(error_message_1.ErrorMessage.DATA_LAYER_TRACKER_UNAVAILABLE);
        }
        trackingData['category'] = this.product;
        this.structuredEventValidator.validate(trackingData);
        this.dataLayerTracker.trackStructuredEvent(Object.assign(Object.assign({}, trackingData), { trackWithGoogleAnalytics: this.trackWithGoogleAnalytics, trackWithSnowplow: this.trackWithSnowplow }), eventName);
    }
    trackExperimentEvent(trackingData) {
        trackingData.category = product_1.Product.EXPERIMENT;
        if (!this.dataLayerTracker) {
            throw new Error(error_message_1.ErrorMessage.DATA_LAYER_TRACKER_UNAVAILABLE);
        }
        if (this.dataLayerTracker !== null) {
            this.dataLayerTracker.trackStructuredEvent(Object.assign(Object.assign({}, trackingData), { trackWithGoogleAnalytics: this.trackWithGoogleAnalytics, trackWithSnowplow: this.trackWithSnowplow }), event_name_1.EventName.EXPERIMENT_EVENT);
        }
    }
    trackStudyCardImpression(trackingData) {
        if (!this.dataLayerTracker) {
            throw new Error(error_message_1.ErrorMessage.DATA_LAYER_TRACKER_UNAVAILABLE);
        }
        new study_card_tracker_1.StudyCardTracker(this.dataLayerTracker).trackImpression(trackingData, {
            trackWithGoogleAnalytics: this.trackWithGoogleAnalytics,
            trackWithSnowplow: this.trackWithSnowplow
        });
    }
    trackScholarshipCardImpression(trackingData) {
        if (!this.dataLayerTracker) {
            throw new Error(error_message_1.ErrorMessage.DATA_LAYER_TRACKER_UNAVAILABLE);
        }
        new scholarship_card_tracker_1.ScholarshipCardTracker(this.dataLayerTracker).trackImpression(trackingData, {
            trackWithGoogleAnalytics: true,
            trackWithSnowplow: this.trackWithSnowplow
        });
    }
    trackUniversityCardImpression(trackingData) {
        if (!this.dataLayerTracker) {
            throw new Error(error_message_1.ErrorMessage.DATA_LAYER_TRACKER_UNAVAILABLE);
        }
        new university_card_tracker_1.UniversityCardTracker(this.dataLayerTracker).trackImpression(trackingData, {
            trackWithGoogleAnalytics: this.trackWithGoogleAnalytics,
            trackWithSnowplow: this.trackWithSnowplow
        });
    }
    trackUniversityCardClick(trackingData) {
        if (!this.dataLayerTracker) {
            throw new Error(error_message_1.ErrorMessage.DATA_LAYER_TRACKER_UNAVAILABLE);
        }
        new university_card_tracker_1.UniversityCardTracker(this.dataLayerTracker).trackClick(trackingData, {
            trackWithGoogleAnalytics: this.trackWithGoogleAnalytics,
            trackWithSnowplow: this.trackWithSnowplow
        });
    }
    trackStudyCardClick(trackingData) {
        if (!this.dataLayerTracker) {
            throw new Error(error_message_1.ErrorMessage.DATA_LAYER_TRACKER_UNAVAILABLE);
        }
        new study_card_tracker_1.StudyCardTracker(this.dataLayerTracker).trackClick(trackingData, {
            trackWithGoogleAnalytics: true,
            trackWithSnowplow: this.trackWithSnowplow
        });
    }
    trackScholarshipCardClick(trackingData) {
        if (!this.dataLayerTracker) {
            throw new Error(error_message_1.ErrorMessage.DATA_LAYER_TRACKER_UNAVAILABLE);
        }
        new scholarship_card_tracker_1.ScholarshipCardTracker(this.dataLayerTracker).trackClick(trackingData, {
            trackWithGoogleAnalytics: true,
            trackWithSnowplow: this.trackWithSnowplow
        });
    }
    trackProductView(trackingData) {
        if (!this.dataLayerTracker) {
            throw new Error(error_message_1.ErrorMessage.DATA_LAYER_TRACKER_UNAVAILABLE);
        }
        new product_view_tracker_1.ProductViewTracker(this.dataLayerTracker).trackImpression(trackingData, {
            trackWithGoogleAnalytics: this.trackWithGoogleAnalytics,
            trackWithSnowplow: this.trackWithSnowplow
        });
    }
    trackPartnerIncentiveImpression(trackingData) {
        if (!this.dataLayerTracker) {
            throw new Error(error_message_1.ErrorMessage.DATA_LAYER_TRACKER_UNAVAILABLE);
        }
        new partner_incentive_tracker_1.PartnerIncentiveTracker(this.dataLayerTracker).trackImpression(trackingData, {
            trackWithGoogleAnalytics: this.trackWithGoogleAnalytics,
            trackWithSnowplow: this.trackWithSnowplow
        });
    }
    trackPartnerIncentiveClick(trackingData) {
        if (!this.dataLayerTracker) {
            throw new Error(error_message_1.ErrorMessage.DATA_LAYER_TRACKER_UNAVAILABLE);
        }
        new partner_incentive_tracker_1.PartnerIncentiveTracker(this.dataLayerTracker).trackClick(trackingData, {
            trackWithGoogleAnalytics: this.trackWithGoogleAnalytics,
            trackWithSnowplow: this.trackWithSnowplow
        });
    }
    trackSiteSearch(trackingData) {
        if (typeof window['snowplow'] === 'undefined') {
            throw new Error('Snowplow not found!');
        }
        window['snowplow']('trackSiteSearch', trackingData);
    }
    initStructuredEventValidators() {
        const eventValidator = new event_validator_1.EventValidator();
        eventValidator.addValidator(new category_validator_1.CategoryValidator);
        eventValidator.addValidator(new action_validator_1.ActionValidator);
        eventValidator.addValidator(new label_validator_1.LabelValidator);
        eventValidator.addValidator(new property_validator_1.PropertyValidator);
        eventValidator.addValidator(new value_validator_1.ValueValidator);
        return eventValidator;
    }
    initTrackerSupport() {
        this.trackerImporter = new tracker_importer_1.TrackerImporter();
        this.trackerImporter.loadTracker(() => {
            this.dataLayerTracker = this.trackerImporter.dataLayerTracker;
        });
        if (this.dataLayerTracker) {
            window['DatalakeEventTrackerLoaded'] = true;
            document.dispatchEvent(new Event('DatalakeEventTrackerLoaded'));
        }
    }
}
exports.Tracker = Tracker;
