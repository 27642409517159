"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryValidator = void 0;
const product_1 = require("../../enums/product");
const error_message_1 = require("../../enums/error-message");
const validation_error_1 = require("../validation-error");
const pascal_case_validator_1 = require("./pascal-case-validator");
class CategoryValidator {
    constructor() {
        this.pascalCaseValidator = new pascal_case_validator_1.PascalCaseValidator();
    }
    validate(trackingData) {
        const category = trackingData.category;
        if (typeof category === 'undefined') {
            throw new validation_error_1.ValidationError(error_message_1.ErrorMessage.INVALID_PRODUCT);
        }
        ;
        if (!Object.values(product_1.Product).includes(category)) {
            throw new validation_error_1.ValidationError(error_message_1.ErrorMessage.INVALID_PRODUCT);
        }
        this.pascalCaseValidator.validate(category);
    }
}
exports.CategoryValidator = CategoryValidator;
