"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniversityCardClickEcommerceTrackingData = void 0;
const product_type_1 = require("../enums/product-type");
const click_ecommerce_tracking_data_class_1 = require("./click-ecommerce-tracking-data.class");
class UniversityCardClickEcommerceTrackingData extends click_ecommerce_tracking_data_class_1.ClickEcommerceTrackingData {
    constructor(productId, listPageType, listName, listPosition) {
        super(productId, listPageType, listName, productId, undefined, undefined, listPosition, undefined, undefined, product_type_1.ProductType.UNIVERSITY);
        this.productId = productId;
        this.listPageType = listPageType;
        this.listName = listName;
        this.listPosition = listPosition;
    }
    getTrackingContext() {
        var _a, _b;
        return {
            organisationId: this.getProductId(),
            studyId: undefined,
            scholarshipId: undefined,
            bodyId: undefined,
            premiumnessLevel: (_b = (_a = this.premium) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '',
            price: undefined,
            position: this.listPosition,
            currency: this.getCurrency()
        };
    }
}
exports.UniversityCardClickEcommerceTrackingData = UniversityCardClickEcommerceTrackingData;
