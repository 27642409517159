"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackerImporter = void 0;
const datalayer_1 = require("@studyportals/datalayer");
class TrackerImporter {
    constructor() {
        this.trackerObject = null;
        this.dataLayerTrackerObject = null;
        this.trackerIsLoaded = false;
    }
    get tracker() {
        return this.trackerObject;
    }
    get dataLayerTracker() {
        return this.dataLayerTrackerObject;
    }
    get trackerLoaded() {
        return this.trackerIsLoaded;
    }
    loadTracker(callback) {
        if (typeof window['dataLayer'] !== 'undefined') {
            this.dataLayerTrackerObject = new datalayer_1.DataLakeEventDispatcher();
            callback(this.dataLayerTrackerObject);
            return;
        }
        document.addEventListener(datalayer_1.EventType.DATA_LAYER_INITIALISED, () => {
            this.dataLayerTrackerObject = new datalayer_1.DataLakeEventDispatcher();
            callback(this.dataLayerTrackerObject);
        });
    }
}
exports.TrackerImporter = TrackerImporter;
