"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductType = void 0;
var ProductType;
(function (ProductType) {
    ProductType["STUDY"] = "study";
    ProductType["SCHOLARSHIP"] = "scholarship";
    ProductType["PARTNER"] = "partner";
    ProductType["UNIVERSITY"] = "university";
})(ProductType = exports.ProductType || (exports.ProductType = {}));
